<template>
<div class="start-page">
    <v-card
        class="mx-auto"
        style="margin-top: 100px;"
        max-width="720"
    >
        <v-card-text>
            <p class="text-h4 text--primary">
                GS1 GPC voorspeller<br>voor doe-het-zelf, tuin & dier
            </p>
            <p>Welkom bij de GS1 GPC voorspeller voor de doe-het-zelf, tuin & dier.                 
Met deze gratis tool kun je de categorie van jouw producten voorspellen volgens de GS1 
Global Product Classification (GPC) methode.</p>
<p>De resultaten zijn gebaseerd op ingevoerde data in de GS1 datapool (GDSN). De 
voorspelde GPC code heeft een bepaalde mate van betrouwbaarheid (van 1-3) omdat 
die afhankelijk is van de beschikbare data waarop wij de voorspelling baseren. </p>
<p>Beoordeel dus altijd zelf of de voorspelde code logisch is.</p>
            <v-btn
            depressed
            color="secondary"
            @click="$router.push({ name: 'authorisation', params: { key: $route.params.key, userId: $route.params.userId } })"
            >
            Start
            </v-btn>
        </v-card-text>
    </v-card>
</div>
</template>

<script>

export default {
    name: 'StartPage',
    data() {
        return {
          
        }
    },
    methods: {
      
    }

}
</script>

<style lang="scss" scoped>
.start-page {
    // background-image: url('https://images.unsplash.com/photo-1513467535987-fd81bc7d62f8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1932&q=80');
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
</style>