<template>
    <v-card
        class="mx-auto"
        max-width="1140"
    >
        <v-card-text>
            <div>Stap 2.1/3</div>
            <p class="text-h4 text--primary">
                Controleer de kolommen
            </p>
            <p>Om een goede voorspelling te kunnen doen, moeten de kolommen uit jouw 
Excelbestand overeenkomen met de benodigde datavelden voor de 
voorspeller. Match daarom de volgende velden:</p>
    <ul>
        <li>Product ID</li>
        <li>Merk</li>
        <li>Productnaam/omschrijving</li>
    </ul><br><br>
    <p>Je kunt het dataveld wijzigen door op het grijze blok te klikken. Als een kolom 
niet aan één van de datavelden gekoppeld kan worden, kies dan de waarde 
‘Negeer’.</p>
<p><b>Kies maximaal 1x de waarde: Product ID of merk voor de kolommen.</b> Bijvoorbeeld, het is <u>niet</u> toegestaan om voor meerdere kolommen de waarde ‘merk’ te kiezen</p>
            <table>
                <thead>
                    <tr>
                        <th class="text-left">
                        Kolom in jouw excel
                        </th>
                        <th class="text-left">
                        Inhoud kolom
                        </th>
                        <th class="text-left">
                        Benodigde datavelden voorspeller
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(value, index) in matchingValues" :key="index">
                        <td>{{value.name}}</td>
                        <td>{{value.examples[0]}}</td>
                        <td>
                            <v-select
                            :items="items"
                            label="Waarde"
                            :value="matchingValues[index].mappedTo"
                            return-object filled
                            @input="changeSelect($event, index)"
                            >
                                <template slot="selection" slot-scope="data">
                                    {{ data.item.name }}
                                </template>
                                <template slot="item" slot-scope="data">
                                    {{ data.item.name }}
                                </template>
                            </v-select>
                        </td>
                    </tr>
                    <tr v-if="matchingValues.length < 1">
                        <td>
                            <v-skeleton-loader
                            type="table-cell"
                            ></v-skeleton-loader>
                        </td>
                        <td>
                            <v-skeleton-loader
                            type="table-cell"
                            ></v-skeleton-loader>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="card-actions">
                <div class="card-actions__side">
                    <v-btn @click="$router.push({ name: 'authorisation', params: { userId: $route.params.userId } })" depressed>Terug</v-btn>
                </div>
                <div class="card-actions__side">
                    <v-btn @click="confirmColumns" color="primary">Bevestig kolommen</v-btn>
                </div>
            </div>
            
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'FinalizePage',
    data() {
        return {
           matchingValues: [],
           items: [
               {
                   name: "Product ID",
                   value: "GTIN"
               },
               {
                   name: "Productnaam/omschrijving",
                   value: "descriptionShort"
               },
               {
                   name: "Merk",
                   value: "brandName"
               },
               {
                   name: "Negeer",
                   value: null
               }
           ]
        }
    },
    created() {
        if(!this.$route.params.key || !this.$route.params.matchingValues) {
            this.$router.push({ name: 'authorisation' })
        } else {
            this.matchingValues = this.$route.params.matchingValues;
            console.log(this.matchingValues);
        }
    },
    methods: {
        confirmColumns: async function() {
            let self = this;
            const brandNameCount = self.matchingValues.filter((obj) => obj.mappedTo === 'brandName').length;
            const descriptionShortCount = self.matchingValues.filter((obj) => obj.mappedTo === 'descriptionShort').length;
            const gtinCount = self.matchingValues.filter((obj) => obj.mappedTo === 'GTIN').length;
            let ok = true;
            if(brandNameCount > 1) {
                alert('Merk mag maar één keer toegewezen zijn');
                ok = false;
            } else if(descriptionShortCount < 1) {
                alert('Wijs een productnaam toe');
                ok = false;
            }
             else if(gtinCount < 1) {
                alert('Wijs een product ID toe');
                ok = false;
            }
            if(ok) {
                await this.axios.post(`https://power-class.mlc-services.com/match/upload/finalize/?sessionId=${this.$route.params.key}`, self.matchingValues)
                .then((response) => {
                    if(response.status == 204) {
                        this.$router.push({ name: 'match', params: { key: this.$route.params.key, userId: this.$route.params.userId } })
                    }
                })
                .catch(error => {
                    console.log(error.response)
                    alert('Er ging wat mis. Probeer opnieuw. Zorgt dat u niet op meerdere plekken een product ID invoert.');
                });
            }
        },
        changeSelect(e, index) {
            let self = this;
            self.matchingValues[index].mappedTo = e.value;
        }
        
    },
    computed: {
        
    }
}
</script>

<style lang="scss" scoped>
table {
    width: 100%;
}
</style>