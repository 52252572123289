<template>
    <v-card
        class="mx-auto"
        max-width="720"
    >
        <v-card-text>
            <div>Stap 1/3</div>
            <p class="text-h4 text--primary">
                Contactgegevens invullen
            </p>
            <div class="text--primary">
                Wil je  jouw  gegevens invullen voor je aan de slag gaat? 
Je kunt dan <b>kosteloos</b> de GS1 GPC voorspeller gebruiken.
            </div>
            <div class="mt-12">
                <v-text-field
                label="Naam*"
                v-model="authorisationValues.name"
                ></v-text-field>
                <v-text-field
                label="Bedrijfsnaam*"
                v-model="authorisationValues.company"
                ></v-text-field>
                <v-text-field
                label="Emailadres*"
                v-model="authorisationValues.email"
                ></v-text-field>

                <v-select
                :items="countries"
                label="Land*"
                @input="changeSelect($event)"
                ></v-select>
            </div>
            <p class="text-primary">*Gegevens verplicht  invullen.</p>
        </v-card-text>
        
        <!-- {{authorisationValues}} -->
        <v-card-actions>
            <v-btn
            depressed
            color="secondary"
            @click="submitForm"
            >
            Verder
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>

export default {
    name: 'AuthorisationPage',
    data() {
        return {
            authorisationValues: {
                name: '',
                email: '',
                company: '',
                country: ''
            },
            countries: ['Nederland', 'België'],
            select: 'NL'
        }
    },
    created() {
        if(this.$route.params.userId) {
            const params = new URLSearchParams({userId: this.$route.params.userId});
            this.axios.post(`https://power-class.mlc-services.com/authorize/?${params.toString()}`)
            .then((response) => {
                this.$router.push({ name: 'inputs', params: { key: response.data.sessionId, userId: response.data.userId } })
            })
            .catch(error => {
                console.log(error.response)
                alert('Er ging wat mis. Probeer opnieuw.');
            });
        }
    },
    methods: {
        validateEmail() {
            // eslint-disable-next-line no-useless-escape
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.authorisationValues.email)) {
                return false;
            } else {
                return true;
            }
        },
        changeSelect(e) {
            if(e === 'Nederland') {
                this.authorisationValues.country = 'NL';
            } else {
                this.authorisationValues.country = 'BE';
            }
        },
        submitForm() {
            const validateEmail = (email) => {
                return email.match(
                    // eslint-disable-next-line no-useless-escape
                    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
            };
            if(validateEmail(this.authorisationValues.email) !== null) {
                var ok = true;
                for(var v in this.authorisationValues){
                    if(this.authorisationValues[v].length === 0) {
                        ok = false;
                    } 
                }
                if(ok) {
                    const params = new URLSearchParams(this.authorisationValues);
                    this.axios.post(`https://power-class.mlc-services.com/authorize/?${params.toString()}`)
                    .then((response) => {
                        this.$router.push({ name: 'inputs', params: { key: response.data.sessionId, userId: response.data.userId } })
                    })
                    .catch(error => {
                        console.log(error.response)
                        alert('Er ging wat mis. Probeer opnieuw.');
                    });
                } else {
                    alert('Vul alstublieft alle waarden in.');
                }
            } else {
                alert('Vul alstublieft een geldig emailadres in.');
            }
        }
    }

}
</script>

<style lang="scss" scoped>
</style>