import Vue from 'vue'
import Router from 'vue-router'

import StartPage from "@/pages/StartPage.vue"
import AuthorisationPage from "@/pages/AuthorisationPage.vue"
import InputsPage from "@/pages/InputsPage.vue"
import MatchPage from "@/pages/MatchPage.vue"
import FinalizePage from "@/pages/FinalizePage.vue"
import EndPage from "@/pages/EndPage.vue"

Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [
      {
        path: '/',
        name: 'start',
        component: StartPage
      },
      {
        path: '/authorisation',
        name: 'authorisation',
        component: AuthorisationPage
      },
      {
        path: '/inputs',
        name: 'inputs',
        component: InputsPage
      },
      {
        path: '/match',
        name: 'match',
        component: MatchPage
      },
      {
        path: '/finalize',
        name: 'finalize',
        component: FinalizePage
      },
      {
        path: '/end',
        name: 'end',
        component: EndPage
      },
      {
        path: '*',
        redirect: 'start'
      }
    ]
  })
  