<template>
<div class="start-page">
    <v-card
        class="mx-auto"
        style="margin-top: 100px;"
        max-width="720"
    >
        <v-card-text>
            <p class="text-h4 text--primary">
                Resultaten downloaden
            </p>
            <p>Vind je dat niet de juiste GPC codes worden voorspeld? Dat zou kunnen want de voorspeller toont alleen actieve GPC productgroepen waaraan in het datamodel commerciële attributen zijn gekoppeld. Neem contact met GS1 Nederland (<a href="mailto:accounts@gs1.nl">accounts@gs1.nl</a>) of GS1 Belgium & Luxembourg (<a href="mailto:diy@gs1belu.org">diy@gs1belu.org</a>). Wellicht zijn er nog GPC codes beschikbaar die wij voor jouw producten kunnen activeren.</p>
            <div class="card-actions">
                <div class="card-actions__side">
                    <v-btn
                    depressed
                    color="secondary"
                    @click="downloadFile()"
                    >
                    Download
                    </v-btn>
                </div>
                <div class="card-actions__side">
                    <v-btn
                    depressed
                    @click="$router.push({ name: 'authorisation', params: {userId: $route.params.userId} })"
                    >
                    Start opnieuw
                    </v-btn>
                </div>
            </div>
        </v-card-text>
    </v-card>
</div>
</template>

<script>

export default {
    name: 'EndPage',
    data() {
        return {
          
        }
    },
    created() {
        if(!this.$route.params.key) {
            this.$router.push({ name: 'authorisation' })
        }
    },
    methods: {
      downloadFile: async function() {
           await this.axios.get(`https://power-class.mlc-services.com/match/download?sessionId=${this.$route.params.key}`, { responseType: 'blob' })
            .then((response) => {
                console.log(response.data);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${Date.now()}.xlsx`);
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                console.log(error.response)
                alert('Er ging wat mis. Probeer opnieuw.');
            });
      }
    }

}
</script>

<style lang="scss" scoped>
.start-page {
    // background-image: url('https://images.unsplash.com/photo-1513467535987-fd81bc7d62f8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1932&q=80');
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
</style>