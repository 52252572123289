<template>
    <v-card
        class="mx-auto"
        max-width="720"
    >
        <v-card-text>
            <div>Stap 2/3</div>
            <p class="text-h4 text--primary">
                Productgegevens invullen
            </p>
            <p>Kies hier of je van één of meerdere producten de GPC wilt opvragen. Voor één 
product voer je informatie handmatig in. Bij meerdere producten kun je dat 
doen door het uploaden van een Excelbestand.</p>
            <div v-if="!amount" class="text--primary mt-8">
                <v-btn
                depressed
                color="primary"
                @click="amount = 'single'"
                >
                Ik wil de GPC van één product opvragen
                </v-btn><br>
                 <v-btn
                depressed
                color="primary"
                class="mt-2"
                @click="amount = 'multiple'"
                >
                Ik wil de GPC van meerdere producten opvragen
                </v-btn>
            </div>
            <div v-if="amount === 'single'" class="mt-12">
               <p class="text-h5 text--primary">
                    De GPC van één product opvragen
                </p>
                <p>Vul hieronder de informatie in van je product en we stellen direct een GPC 
voor je het product voor. </p><p>
Vul <b>alle</b> onderstaande velden in om de betrouwbaarheid van de voorspelling 
te verhogen. De product ID en productnaam/omschrijving zijn minimaal vereist. Klik voor 
meer informatie op de ℹ️.</p>
                <div class="input-label">Product ID: GS1 artikelcode (EAN/GTIN) of productnummer van 
leverancier*
                    <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                        <span
                        v-bind="attrs"
                        v-on="on"
                        >
                        ℹ️
                        </span>
                        </template>
                        <span>
                            <p>De GS1 artikelcode of Global Trade Item Number (GTIN/EAN-13) is 
de internationale standaard om producten uniek te identificeren.</p>
                        </span>
                    </v-tooltip>
                </div>
                <v-text-field
                v-model="products[0].GTIN"
                class="input"
                ></v-text-field>
                <div class="input-label">Merk
                    <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                        <span
                        v-bind="attrs"
                        v-on="on"
                        >
                        ℹ️
                        </span>
                        </template>
                        <span>
                            <p>De naam van het merk waaronder het product wordt verkocht en gepromoot. De 
merknaam moet overeenkomen met wat er op de verpakking staat. Spel de merknaam 
altijd op dezelfde manier en gebruik leestekens, hoofdletters en kleine letters consistent. 
Voer bij merkloze artikelen de waarde UNBRANDED in, en bij combinatieverpakkingen 
met daarin verschillende merken de waarde MIXED.<br><br>
Voorbeeld: Flexa</p>
                            
                        </span>
                    </v-tooltip>
                </div>
                <v-text-field
                v-model="products[0].descriptions[1].description"
                class="input"
                ></v-text-field>
                <div class="input-label">Productnaam/omschrijving*
                    <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                        <span
                        v-bind="attrs"
                        v-on="on"
                        >
                        ℹ️
                        </span>
                        </template>
                        <span>
                            <p>De naam/omschrijving die de leverancier aan het product heeft gegeven. De productnaam/omschrijving is voor ieder product uniek en bevat vaak informatie over het merk, producttype, kleur, afmeting, inhoud, enzovoort.<br><br>
Voorbeeld: Flexa Strak in de lak voor binnen RAL 9010 gebroken wit zijdeglans 750 ml</p>
                        </span>
                    </v-tooltip>
                </div>
                <v-text-field
                v-model="products[0].descriptions[0].description"
                class="input"
                ></v-text-field>
                <div class="card-actions">
                    <div class="card-actions__side">
                        <v-btn
                        depressed
                        @click="amount = false"
                        >
                        Terug
                        </v-btn>
                    </div>
                    <div class="card-actions__side">
                        <v-btn
                        depressed
                        color="secondary"
                        @click="submitSingle"
                        >
                        Verder
                        </v-btn>
                    </div>
                </div>
            </div>
            
            <div v-else-if="amount === 'multiple'" class="mt-12">
            <a href="/voorbeeld.xlsx" target="_blank"><v-btn>Download voorbeeldbestand</v-btn></a>
            <br><br><br>
               <p class="text-h5 text--primary">
                    De GPC van meerdere producten opvragen
                </p>
                <p>Om de GPC van meerdere producten tegelijk te voorspellen, kun je hieronder 
een Excelbestand met informatie over je producten uploaden.</p>
<p>Om de betrouwbaarheid van de resultaten te verhogen, vragen wij om <b>alle</b>   
onderstaande kolommen in het Excelbestand op te nemen. De product ID en 
productnaam/omschrijving moeten minimaal in het bestand zijn gevuld. Klik voor meer 
informatie op de ℹ️.</p>
                    <div>Product ID: GS1 artikelcode (EAN/GTIN) of productnummer van de 
leverancier*
                        <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                        <span
                        v-bind="attrs"
                        v-on="on"
                        >
                        ℹ️
                        </span>
                        </template>
                        <span>
                            <p>De GS1 artikelcode of Global Trade Item Number (GTIN/EAN-13) is 
de internationale standaard om producten uniek te identificeren.</p>
                        </span>
                        </v-tooltip>
                    </div>
                    <div>Merk
                        <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                        <span
                        v-bind="attrs"
                        v-on="on"
                        >
                        ℹ️
                        </span>
                        </template>
                        <span>
                            <p>De naam van het merk waaronder het product wordt verkocht en gepromoot. De 
merknaam moet overeenkomen met wat er op de verpakking staat. Spel de merknaam 
altijd op dezelfde manier en gebruik leestekens, hoofdletters en kleine letters consistent. 
Voer bij merkloze artikelen de waarde UNBRANDED in, en bij combinatieverpakkingen 
met daarin verschillende merken de waarde MIXED.<br><br>
Voorbeeld: Flexa</p>
                            
                        </span>
                        </v-tooltip>
                    </div>
                    <div>Productnaam/omschrijving*
                        <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                        <span
                        v-bind="attrs"
                        v-on="on"
                        >
                        ℹ️
                        </span>
                        </template>
                        <span>
                            <p>De naam/omschrijving die de leverancier aan het product heeft gegeven. De productnaam/omschrijving is voor ieder product uniek en bevat vaak informatie over het merk, producttype, kleur, afmeting, inhoud, enzovoort.<br><br>
Voorbeeld: Flexa Strak in de lak voor binnen RAL 9010 gebroken wit zijdeglans 750 ml</p>
                        </span>
                        </v-tooltip>
                    </div>
                    <br><br><br>
                <v-file-input
                chips
                label="Upload bestand"
                v-model="file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                ></v-file-input>
                <!-- <input type="file" @change="changeFile($event)" accept="xlsx" class="input-file"> -->
                <div class="card-actions">
                    <div class="card-actions__side">
                        <v-btn
                        depressed
                        @click="amount = false"
                        >
                        Terug
                        </v-btn>
                    </div>
                    <div class="card-actions__side">
                        <v-btn v-if="file" @click="submitMultiple" color="secondary">Verstuur bestand</v-btn>
                    </div>
                </div>
                
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'InputsPage',
    data() {
        return {
            amount: false,
            products: [
                {
                    GTIN: '',
                    "descriptions": [
                        {
                        "columnName": "descriptionShort",
                        "description": ""
                        },
                        {
                        "columnName": "brandName",
                        "description": ""
                        },
                        {
                        "columnName": "tradeItemDescriptionInformation",
                        "description": ""
                        }
                    ],
                }
            ],
            file: false
        }
    },
    created() {
        if(!this.$route.params.key && !this.$route.params.userId) {
            this.$router.push({ name: 'authorisation' })
        }
        if(this.$route.params.amount) {
            this.amount = this.$route.params.amount;
        }
    },
    methods: {
        submitSingle() {
            let self = this;
            var ok = true;
            for(var v in this.products){
                if(this.products[v].GTIN.length === 0) {
                    ok = false;
                    alert('Vul alstublieft een product ID in');
                }
                if(this.products[v].descriptions[0].description.length === 0) {
                    ok = false;
                    alert('Vul alstublieft een productnaam in');
                }
            }
            if(ok) {
                //const params = new URLSearchParams(this.products);
                
                // ?name=Jelmer Wind&email=jelmer@wind.com&company=Squadra&country=The Netherlands
                this.axios.post(`https://power-class.mlc-services.com/match/?sessionId=${this.$route.params.key}`, self.products)
                .then((response) => {
                    console.log(response);
                    if (response.status == 204) {
                        this.$router.push({ name: 'match', params: { key: this.$route.params.key, userId: this.$route.params.userId } })
                    }
                })
                .catch(error => {
                    console.log(error.response)
                    alert('Er ging wat mis. Probeer opnieuw.');
                });
            }
            //  else {
            //     alert('Vul alstublieft overal een waarde in.');
            // }
        },
        changeFile(e) {
            console.log(e.target.files);
            var formData = new FormData();
            formData.append('file', e.target.files[0]);
        },
        submitMultiple() {
            let self = this;
            let formData = new FormData();
            formData.append("file", self.file);
            for (var pair of formData.entries()) {
                console.log(pair[0]+ ', ' + pair[1]); 
            }

            let config = {
                header : {
                    'Content-Type' : 'multipart/form-data'
                }
            }
            
            this.axios.post(`https://power-class.mlc-services.com/match/upload/?sessionId=${this.$route.params.key}`, formData, config)
            .then((response) => {
                if (response.status == 200) {
                    console.log(response);
                    this.$router.push({ name: 'finalize', params: { key: this.$route.params.key, matchingValues: response.data, userId: this.$route.params.userId } })
                }
            })
            .catch(error => {
                console.log(error.response)
                alert('Er ging wat mis. Probeer opnieuw.');
            });
        },
        downloadExample() {
            this.axios.get(`https://gs1.powerclass.ai/voorbeeld.xlsx`, { responseType: 'blob' })
            .then((response) => {
                const blob = new Blob([response.data], { type: 'application/pdf' })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = 'Voorbeeld excel'
                link.target = '_blank'
                link.click()
                URL.revokeObjectURL(link.href)
            })
            .catch(error => {
                console.log(error.response)
                alert('Er ging wat mis. Probeer opnieuw.');
            });
        }
    }

}
</script>

<style lang="scss">
.input-label {
    margin-top: .75rem;
    display: flex;
    // justify-content: space-between;
    &:first-child {
        margin-top: 2rem;
    }
    span {
        margin-left: .5rem;
    }
}

.input {
    padding-top: 0;
}
.v-tooltip__content span {
    display: inline-block;
    max-width: 300px;
}

.card-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    width: 100%;
    &__side {
        display: flex;
    }
}
</style>