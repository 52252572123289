<template>
  <v-app>
    <v-app-bar
      app
      color="light"
      extended
      height="80px"
    >
      <div class="d-flex align-center">
        <div class="logo">
          <img src="/img/gs1-nederland.jpg" style="height: 50px; margin-right: .5rem;" />
        </div>
        <div class="logo">
<img src="/img/gs1-belu.png" style="height: 50px;" />        </div>
      </div>

      <v-spacer></v-spacer>

      <v-btn
        href="https://images.gs1.nl/Global_Product_Classification.mp4"
        target="_blank"
        text
      >
        <span class="mr-2">Bekijk het GPC filmpje</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
      <template #extension>
        <div class="text-h6">GS1 GPC voorspeller voor doe-het-zelf, tuin & dier</div>
      </template>
    </v-app-bar>

    <v-main>
      <v-container>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  components: {
  },

  data: () => ({
    //
  }),
};
</script>

<style lang="scss">
.logo {
  svg {
    height: 3rem;
  }
}
.container {
  padding-top: 5rem;
}
.v-toolbar__content {
  background-color: #ffffff !important;
}
.v-toolbar__extension {
  background-color: #f26334 !important;
  color: #ffffff !important;
}
</style>