<template>
    <v-card
        class="mx-auto"
    >
        <v-card-text>
            <div>Stap 3/3</div>
            <p class="text-h4 text--primary">
                Controleer de GPC codes
            </p>
            <p>
                De GS1 GPC voorspeller heeft op basis van de door jou ingevoerde data een 
voorspelling gedaan. Controleer of de code bij jouw product past. Lees voor 
de zekerheid de GPC omschrijving door op de i te klikken. Klik op ‘bevestigen’ 
om het resultaat in een Excelbestand te downloaden.
            </p>
            <table>
                <thead>
                    <tr>
                        <th class="text-left">
                        Product ID
                        </th>
                        <th class="text-left">
                        Productnaam/omschrijving
                        </th>
                        <th class="text-left">
                        Merknaam
                        </th>
                        <th class="text-left">
                        Voorspelde GPC code
                        </th>
                        <th class="text-left">
                        </th>
                        <th class="text-left">
                        Betrouwbaarheid
                        <v-tooltip left>
                                <template v-slot:activator="{ on, attrs }">
                                <span
                                v-bind="attrs"
                                v-on="on"
                                >
                                ℹ️
                                </span>
                                </template>
                                <span>
                                    1. Groen - Heel betrouwbaar<br>
2. Oranje - Betrouwbaar<br>
3. Rood - Minder betrouwbaar<br>
                                </span>
                            </v-tooltip>
                        </th>
                        <th class="text-left">
                        Andere code
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="matches.length > 0">
                    <tr v-for="product in matches" :key="product.key">
                        <td>{{product.GTIN}}</td>
                        <td><template v-if="product.descriptions">{{product.descriptions[0].description}}</template></td>
                        <td><template v-if="product.descriptions">{{product.descriptions[1].description}}</template></td>
                        <td>
                            <v-select
                            :items="product.classifications"
                            v-model="product.selectedClassification"
                            return-object filled
                            @input="changeSelect($event, product.GTIN)"
                            height="20"
                            solo
                            >
                                <template slot="selection" slot-scope="data">
                                    {{ data.item.is_human_defined ? 'Andere code' : data.item.category.code }} {{ data.item.is_human_defined ? '' : ' - ' + data.item.category.name }}
                                </template>
                                <template slot="item" slot-scope="data">
                                    {{ data.item.is_human_defined ? 'Andere code' : data.item.category.code }} {{ data.item.is_human_defined ? '' : ' - ' + data.item.category.name }}
                            </template>
                            </v-select>
                        </td>
                        <td>
                            <v-tooltip v-if="product.selectedClassification !== undefined" left>
                                <template v-slot:activator="{ on, attrs }">
                                <span
                                v-bind="attrs"
                                v-on="on"
                                >
                                <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="match-svg"><path d="M160 256C160 185.3 217.3 128 288 128C358.7 128 416 185.3 416 256C416 326.7 358.7 384 288 384C217.3 384 160 326.7 160 256zM288 336C332.2 336 368 300.2 368 256C368 211.8 332.2 176 288 176C287.3 176 286.7 176 285.1 176C287.3 181.1 288 186.5 288 192C288 227.3 259.3 256 224 256C218.5 256 213.1 255.3 208 253.1C208 254.7 208 255.3 208 255.1C208 300.2 243.8 336 288 336L288 336zM95.42 112.6C142.5 68.84 207.2 32 288 32C368.8 32 433.5 68.84 480.6 112.6C527.4 156 558.7 207.1 573.5 243.7C576.8 251.6 576.8 260.4 573.5 268.3C558.7 304 527.4 355.1 480.6 399.4C433.5 443.2 368.8 480 288 480C207.2 480 142.5 443.2 95.42 399.4C48.62 355.1 17.34 304 2.461 268.3C-.8205 260.4-.8205 251.6 2.461 243.7C17.34 207.1 48.62 156 95.42 112.6V112.6zM288 80C222.8 80 169.2 109.6 128.1 147.7C89.6 183.5 63.02 225.1 49.44 256C63.02 286 89.6 328.5 128.1 364.3C169.2 402.4 222.8 432 288 432C353.2 432 406.8 402.4 447.9 364.3C486.4 328.5 512.1 286 526.6 256C512.1 225.1 486.4 183.5 447.9 147.7C406.8 109.6 353.2 80 288 80V80z"/></svg> -->
                                ℹ️
                                </span>
                                </template>
                                <span v-if="!product.selectedClassification.is_human_defined">
                                    <v-row>
                                        <v-col>
                                            <h4>Bij deze categorie hoort wel:</h4><br>
                                            <p>{{product.selectedClassification.category.descriptions[0].description}}</p>
                                        </v-col>
                                        <v-col>
                                            <h4>Bij deze categorie hoort niet:</h4><br>
                                            <p>{{product.selectedClassification.category.descriptions[1].description}}</p>
                                        </v-col>
                                    </v-row>
                                </span>
                                <span v-else>Je hebt een eigen code ingevoerd</span>
                            </v-tooltip>
                            
                        </td>
                        <td>
                            <template v-if="product.selectedClassification.category.code == product.classifications[0].category.code">
                                <span v-if="!product.selectedClassification.is_human_defined" class="risk-level" :class="{['risk-level--'+product.selectedClassification.riskLevel]: product.selectedClassification.riskLevel}">{{product.selectedClassification.riskLevel}}</span>
                            </template>
                        </td>
                        <td>
                            <v-text-field
                            v-if="product.selectedClassification.is_human_defined"
                            v-model="product.selectedClassification.category.code"
                            type="number"
                            placeholder="Vul hier uw code in"
                            ></v-text-field>
                        </td>
                    </tr>
                    </template>
                    <tr v-else>
                        <td>
                            <v-skeleton-loader
                            type="table-cell"
                            ></v-skeleton-loader>
                        </td>
                        <td>
                            <v-skeleton-loader
                            type="table-cell"
                            ></v-skeleton-loader>
                        </td>

                        <td>
                            <v-skeleton-loader
                            type="table-cell"
                            ></v-skeleton-loader>
                        </td>
                        <td>
                            <v-skeleton-loader
                            type="table-cell"
                            ></v-skeleton-loader>
                        </td>
                        <td>
                            <v-skeleton-loader
                            type="table-cell"
                            ></v-skeleton-loader>
                        </td>
                        <td>
                            <v-skeleton-loader
                            type="table-cell"
                            ></v-skeleton-loader>
                        </td>
                    </tr>
                </tbody>
            </table>
        </v-card-text>
        <v-card-actions v-if="matches.length > 0">
            <div class="card-actions">
                <div class="card-actions__side">
                    <v-btn @click="$router.push({ name: 'authorisation', params: { userId: $route.params.userId } })" depressed>Terug</v-btn>
                </div>
                <div class="card-actions__side">
                    <v-btn @click="setMatch('proceed')" color="secondary" class="mr-2">Bevestigen</v-btn>
                    <v-btn @click="setMatch('another')" color="primary">Bevestigen en nog een product toevoegen</v-btn>
                </div>
            </div>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: 'MatchPage',
    data() {
        return {
           matches: [],
           items: [
               "GTIN", "Beschrijving", "Product feature"
           ]
        }
    },
    created() {
        if(!this.$route.params.key) {
            this.$router.push({ name: 'authorisation' })
        } else {
            this.getMatch();
        }
    },
    methods: {
        getMatch: async function() {
            let hasData = false;
            let self = this;
            if (!hasData) {
                await this.axios.get(`https://power-class.mlc-services.com/match/?sessionId=${this.$route.params.key}`)
                .then((response) => {
                    if(response.data.finished) {
                        hasData = true;
                        console.log(response.data);
                        self.matches = response.data.products;
                        self.matches.forEach(function(product, index) {
                            self.matches[index].selectedClassification = product.classifications[0];
                            self.matches[index].key = 0;
                            self.matches[index].classifications.push({
                                "category": {
                                "code": "",
                                "name": "Manual"
                                },
                                "is_human_defined": true,
                                "position": 1,
                                "riskLevel": 1
                            })
                        });
                    } else {
                        setTimeout(self.getMatch, 5000);
                    }
                })
                .catch(error => {
                    console.log(error.response)
                    alert('Er ging wat mis. Probeer opnieuw.');
                });
            }
        },
        changeSelect(e, GTIN) {
            const index = this.matches.map(match=>match.GTIN).indexOf(GTIN);
            this.matches[index].selectedClassification = e;
            this.matches[index].key += 1;
            this.$forceUpdate();
        },
        setMatch: async function(direction) {
            let self = this;
            let selectedClassifications = self.matches;
            console.log(selectedClassifications);
            self.matches.forEach((match, index) => {
                delete selectedClassifications[index]["classifications"];
                delete selectedClassifications[index]["descriptions"];
                delete selectedClassifications[index]["key"];
            })
            await this.axios.put(`https://power-class.mlc-services.com/match/classification/?sessionId=${this.$route.params.key}`, {
                "products": selectedClassifications
            })
            .then((response) => {
                if(response.status === 204) {
                    if(direction === 'proceed') {
                        this.$router.push({ name: 'end', params: { key: this.$route.params.key, products: response.data, userId: this.$route.params.userId } })
                    } else if(direction === 'another') {
                        this.$router.push({ name: 'inputs', params: { key: this.$route.params.key, userId: this.$route.params.userId, amount: 'single' } })
                    }
                }
            })
            .catch(error => {
                console.log(error.response)
                if(error.response.status === 400) {
                    alert('Je hebt een categorie code ingevuld die niet bestaat. Vul alsjeblieft een code in die wel bestaat.');
                } else {
                    alert('Er ging wat mis. Probeer opnieuw.');
                }
            });
        }
        
    },
    computed: {
        
    }
}
</script>

<style lang="scss">
.risk-level {
    padding: .5rem .75rem;
    border-radius: .25rem;
    font-weight: 600;
    &--1 {
        background-color: #71B790;
        color: #ffffff;
    }
    &--2 {
        background-color: #FBB034;
    }
    &--3 {
        background-color: #FF8200;
    }
}

table {
    width: 100%;
    th {
        white-space: nowrap;
    }
    td {
        padding: .25rem .5rem .25rem 0;
    }
}

.match-svg {
    width: 24px;
    margin-left: 1rem;
}

.v-text-field__details {
    display: none;
}
</style>